import en from '../locale/translations/en.json'
import de from '../locale/translations/de.json'
import fr from '../locale/translations/fr.json'
import es from '../locale/translations/es.json'
import { InitOptions } from 'i18next'

export default {
  resources: { en, de, fr, es },
  supportedLngs: ['en', 'de', 'fr', 'es'],
  missingInterpolationHandler: () => '',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: { useSuspense: false },
  returnObjects: true,
} satisfies InitOptions
