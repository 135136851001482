import { CacheProvider, EmotionCache } from '@emotion/react'

export default function MuiProvider({
  children,
  value,
}: {
  children: React.ReactNode
  value: EmotionCache
}) {
  return <CacheProvider value={value}>{children}</CacheProvider>
}
